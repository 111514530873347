// AboutUs.js
import React from 'react';
import './AboutUs.css';
import { Container, Typography, Grid } from '@mui/material';
import { Work, People, ThumbUp, Flag } from '@mui/icons-material';
import logo from '../images/Logo.png';


const AboutUs = () => {
  return (
    <Container className="aboutUsContainer">
      <Grid container spacing={3} className="aboutUsHeader">
        <Grid item xs={12} md={6} className="logoContainer">
          <img src={logo} alt="Arc Codes Logo" className="logo" />
        </Grid>
        <Grid item xs={12} md={6} className="taglineContainer">
          <Typography variant="h3" className="tagline">
            Your Cheat Code to Success
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={4} className="aboutUsContent">
        <Grid item xs={12} md={6} className="alternatingRow">
          <div className="sectionIcon">
            <People fontSize="large" />
          </div>
          <Typography variant="h4" className="sectionTitle">
            Who We Are
          </Typography>
          <Typography variant="body1" className="sectionText">
            Arc Codes was established by Silal Anwar and Ahsan Mughal in 2022. Since then, we have been providing exceptional software development services to our clients, both local and international. Our team of experts works tirelessly to deliver top-quality solutions tailored to your needs.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className="alternatingRow">
          <img src="https://images.pexels.com/photos/380769/pexels-photo-380769.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Expertise" className="sectionImage" />
        </Grid>

        <Grid item xs={12} md={6} className="alternatingRow">
          <img src="https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Trust" className="sectionImage" />
        </Grid>
        <Grid item xs={12} md={6} className="alternatingRow">
          <div className="sectionIcon">
            <ThumbUp fontSize="large" />
          </div>
          <Typography variant="h4" className="sectionTitle">
            Why Arc Codes?
          </Typography>
          <Typography variant="body1" className="sectionText">
            Our commitment to delivering high-quality services and our attention to customer needs set us apart from the rest. We have worked with a variety of clients, both local and international, who trust us for our expertise and reliability. We believe in building lasting relationships and are dedicated to seeing our clients succeed.
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} className="alternatingRow">
          <div className="sectionIcon">
            <Work fontSize="large" />
          </div>
          <Typography variant="h4" className="sectionTitle">
            What We Do
          </Typography>
          <Typography variant="body1" className="sectionText">
            We specialize in web development, mobile app development, digital marketing, graphic design, data analytics, and e-commerce solutions. Our expertise and passion drive us to create innovative and effective solutions tailored to your needs.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className="alternatingRow">
          <img src="https://images.pexels.com/photos/39284/macbook-apple-imac-computer-39284.jpeg?auto=compress&cs=tinysrgb&w=600" alt="What We Do" className="sectionImage" />
        </Grid>

        <Grid item xs={12} md={6} className="alternatingRow">
          <img src="https://images.pexels.com/photos/6147381/pexels-photo-6147381.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Our Mission" className="sectionImage" />
        </Grid>
        <Grid item xs={12} md={6} className="alternatingRow">
          <div className="sectionIcon">
            <Flag fontSize="large" />
          </div>
          <Typography variant="h4" className="sectionTitle">
            Our Mission
          </Typography>
          <Typography variant="body1" className="sectionText">
            Our mission is to empower businesses with innovative technology solutions that drive success. We aim to be the trusted partner for organizations of all sizes, delivering quality and innovation that stands out. At Arc Codes, we believe that collaboration and creativity are key to helping our clients unlock their full potential.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AboutUs;