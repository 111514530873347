import React from 'react';
import { Container, Typography, Box, Grid, Card, CardContent, CardMedia } from '@mui/material';
import { motion } from 'framer-motion';
import './ServicePageTemplate.css';

const ServicePageTemplate = ({ title, description, imageUrl, benefits }) => {
  const relatedServices = [
    {
      title: 'Digital Marketing',
      shortDescription: 'Boost your brand presence with our social media and digital marketing experts.',
      imageUrl: 'https://images.pexels.com/photos/607812/pexels-photo-607812.jpeg?auto=compress&cs=tinysrgb&w=600',
      link: '/socialmedia'
    },
    {
      title: 'SEO Services',
      shortDescription: 'Improve your website\'s visibility on search engines and drive organic traffic.',
      imageUrl: 'https://images.pexels.com/photos/270637/pexels-photo-270637.jpeg?auto=compress&cs=tinysrgb&w=600',
      link: '/seo'
    },
    {
      title: 'UI/UX Design',
      shortDescription: 'Create intuitive, engaging, and user-friendly interfaces that delight your users.',
      imageUrl: 'https://images.pexels.com/photos/326514/pexels-photo-326514.jpeg?auto=compress&cs=tinysrgb&w=600',
      link: '/uiux'
    }
  ];

  // Remove the current service from related services
  const filteredRelatedServices = relatedServices.filter(service => service.title !== title);

  return (
    <Container maxWidth="lg">
      {/* Hero Section */}
      <Box className="hero-section" style={{ backgroundImage: `url(${imageUrl})` }}>
        <Box>
          <Typography variant="h2" component="h1" gutterBottom>
            {title}
          </Typography>
          <Typography variant="h5">{description}</Typography>
        </Box>
      </Box>

      {/* Benefits Section */}
      <Box padding={4} className="benefits-section">
        <Typography variant="h4" component="h2" gutterBottom>
          Why Choose Our Services
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {benefits.map((benefit, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <motion.div whileHover={{ scale: 1.1 }} className="benefit-card">
                <Card className="benefit-card-content">
                  <CardMedia
                    component="img"
                    alt={`Benefit ${index + 1}`}
                    height="150"
                    image={benefit.imageUrl}
                  />
                  <CardContent>
                    <Typography variant="h6" component="h3">
                      {benefit.title}
                    </Typography>
                    <Typography variant="body2">
                      {benefit.description}
                    </Typography>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Our Process Section */}
      <Box className="process-section">
        <Typography variant="h4" component="h2" gutterBottom>
          Our Development Lifecycle
        </Typography>
        <Typography variant="body1" paragraph>
          We have crafted a meticulous and proven process to bring your vision to life, ensuring that each phase is handled with the utmost care and precision.
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {[{
            step: 'Discovery',
            description: 'We start with understanding your business goals, audience, and unique value proposition to tailor a solution just for you.',
            imageUrl: 'https://images.pexels.com/photos/1194775/pexels-photo-1194775.jpeg?auto=compress&cs=tinysrgb&w=600'
          }, {
            step: 'Planning',
            description: 'A detailed plan is crafted, complete with timelines and milestones, ensuring all requirements are addressed.',
            imageUrl: 'https://images.pexels.com/photos/733857/pexels-photo-733857.jpeg?auto=compress&cs=tinysrgb&w=600'
          }, {
            step: 'Design',
            description: 'Our creative team designs visually stunning and user-friendly interfaces that resonate with your brand identity.',
            imageUrl: 'https://images.pexels.com/photos/196644/pexels-photo-196644.jpeg?auto=compress&cs=tinysrgb&w=600'
          }, {
            step: 'Development',
            description: 'Our team of experts carry out your requirements, with an emphasis on performance, and scalability',
            imageUrl: 'https://images.pexels.com/photos/1181675/pexels-photo-1181675.jpeg?auto=compress&cs=tinysrgb&w=600'
          }, {
            step: 'Testing',
            description: 'Rigorous testing is performed to ensure you get what you want. Your satisfaction is our key priority',
            imageUrl: 'https://images.pexels.com/photos/1181244/pexels-photo-1181244.jpeg?auto=compress&cs=tinysrgb&w=600'
          }, {
            step: 'Launch',
            description: 'We launch your product with full support, ensuring a smooth transition and immediate impact.',
            imageUrl: 'https://images.pexels.com/photos/796206/pexels-photo-796206.jpeg?auto=compress&cs=tinysrgb&w=600'
          }].map((process, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <motion.div whileHover={{ scale: 1.05 }}>
                <Card className="service-card">
                  <CardMedia
                    component="img"
                    alt={process.step}
                    height="200"
                    image={process.imageUrl}
                  />
                  <CardContent>
                    <Typography variant="h6" component="h3">
                      {process.step}
                    </Typography>
                    <Typography variant="body2">
                      {process.description}
                    </Typography>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Related Services Section */}
      <Box className="related-services-section">
        <Typography variant="h4" component="h2" gutterBottom>
          Other Services You Might Be Interested In
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {filteredRelatedServices.map((service, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <motion.div whileHover={{ scale: 1.05 }}>
                <Card className="service-card">
                  <CardMedia
                    component="img"
                    alt={service.title}
                    height="200"
                    image={service.imageUrl}
                  />
                  <CardContent>
                    <Typography variant="h6" component="h3">
                      {service.title}
                    </Typography>
                    <Typography variant="body2">{service.shortDescription}</Typography>
                  </CardContent>

                </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default ServicePageTemplate;

