// src/pages/Home.js

import React from 'react';
import { Box, Button, Grid, Typography, Card, CardContent, CardMedia } from '@mui/material';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { CheckCircle, People, TrendingUp } from '@mui/icons-material';
import './Home.css';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';

function Home() {
  // Slider settings for client logos
  const settings = {
    infinite: true,
    speed: 5000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    arrows: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Box className="homeContainer">
      {/* Hero Section with Background Video */}
      <Box className="heroSection">
        <video autoPlay loop muted className="heroVideo">
          <source src="https://res.cloudinary.com/dt98ksead/video/upload/v1731572384/3129957-hd_1280_720_25fps_upoccs.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <Box className="heroTextContainer">
          <Typography variant="h3" className="heroTitle">
            Your Cheat Code to Success
          </Typography>
          <Typography variant="h6" className="heroSubtitle" sx={{marginTop:"1rem"}}>
            Innovative solutions that bring your ideas to life.
          </Typography>
          <Button
            component={Link}
            to="/contact"
            variant="contained"
            color="primary"
            className="exploreButton"
            sx={{marginTop:"1rem"}}
          >
            Contact Us
          </Button>
        </Box>
      </Box>

      {/* Services Overview Section */}
      <Box className="servicesSection">
        <Typography variant="h4" className="sectionTitle" sx={{color: "black", marginBottom:"1rem"}}>
          Our Popular Services
        </Typography>
        <Grid container spacing={4} justifyContent="center" className="servicesGrid">
          <Grid item xs={12} md={6} lg={4}>
            <Card className="serviceCard">
              <CardMedia
                component="img"
                image="https://images.pexels.com/photos/196644/pexels-photo-196644.jpeg?auto=compress&cs=tinysrgb&w=600"
                alt="Website Design"
                className="serviceImage"
              />
              <CardContent>
                <Typography variant="h6" className="serviceTitle">
                  Website Design & UI/UX
                </Typography>
                <Typography variant="body2" className="serviceDescription">
                  Crafting visually stunning websites that capture your brand essence.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Card className="serviceCard">
              <CardMedia
                component="img"
                image="https://images.pexels.com/photos/887751/pexels-photo-887751.jpeg?auto=compress&cs=tinysrgb&w=600"
                alt="App Development"
                className="serviceImage"
              />
              <CardContent>
                <Typography variant="h6" className="serviceTitle">
                  Mobile Application Development
                </Typography>
                <Typography variant="body2" className="serviceDescription">
                  Innovative mobile app solutions for Android, iOS and Cross-platform.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Card className="serviceCard">
              <CardMedia
                component="img"
                image="https://images.pexels.com/photos/67112/pexels-photo-67112.jpeg?auto=compress&cs=tinysrgb&w=600"
                alt="SEO & Marketing"
                className="serviceImage"
              />
              <CardContent>
                <Typography variant="h6" className="serviceTitle">
                  SEO & Marketing
                </Typography>
                <Typography variant="body2" className="serviceDescription">
                  Increase visibility and attract more customers with our marketing services.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Why Choose Us Section */}
      <Box className="whyChooseSection">
        <Typography variant="h4" className="sectionTitle" sx={{color: "black", marginBottom:"1rem"}}>
          Why Choose Arc Codes?
        </Typography>
        <Grid container spacing={4} justifyContent="center" className="whyChooseGrid">
          <Grid item xs={12} md={4}>
            <Box className="whyChooseCard">
              <People className="whyChooseIcon" />
              <Typography variant="h5" className="whyChooseTitle">
                Experienced Team
              </Typography>
              <Typography variant="body2" className="whyChooseDescription">
                Our team consists of highly skilled developers and designers with years of experience.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className="whyChooseCard">
              <CheckCircle className="whyChooseIcon" />
              <Typography variant="h5" className="whyChooseTitle">
                Custom Solutions
              </Typography>
              <Typography variant="body2" className="whyChooseDescription">
                We provide tailored solutions that fit your unique business needs.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className="whyChooseCard">
              <TrendingUp className="whyChooseIcon" />
              <Typography variant="h5" className="whyChooseTitle">
                Results-Driven
              </Typography>
              <Typography variant="body2" className="whyChooseDescription">
                We are focused on delivering measurable results that help you succeed.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Testimonials Section */}
      <Box className="testimonialsSection">
        <Typography variant="h4" className="sectionTitle" sx={{color: "black", marginBottom:"1rem"}}>
          What Our Clients Say
        </Typography>
        <Grid container spacing={4} justifyContent="center" className="testimonialsGrid">
          <Grid item xs={12} md={6} lg={4}>
            <Box className="testimonialCard">
              <Typography variant="body1" className="testimonialText">
                "Arc Codes delivered exactly what we needed, on time and with superb quality. Highly recommended!"
              </Typography>
              <Typography variant="subtitle2" className="testimonialAuthor">
                - Saad, Business Executive of Tracking World
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box className="testimonialCard">
              <Typography variant="body1" className="testimonialText">
                "The team at Arc Codes understood our vision and transformed it into a great product. Fantastic job!"
              </Typography>
              <Typography variant="subtitle2" className="testimonialAuthor">
                - Amel, Chief Editor of Earthwise
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Clients Logo Slider Section */}
      <Box className="clientsSection">
        <Typography variant="h4" className="sectionTitle" sx={{color: "black", marginBottom:"1rem"}}>
          Companies That Trust Us
        </Typography>
        <Slider {...settings} className="clientsSlider">
          <Box className="clientLogo">
            <img src="https://res.cloudinary.com/dt98ksead/image/upload/v1731573645/Fuji_Logo_l5wr7y.png" alt="Client 4" />
          </Box>
          <Box className="clientLogo">
            <img src="https://res.cloudinary.com/dt98ksead/image/upload/v1731574605/Honda_20Ring_20Road_ew7gkw.webp" alt="Client 1" />
          </Box>
          <Box className="clientLogo">
            <img src="https://res.cloudinary.com/dt98ksead/image/upload/v1731681020/Tracking-World-PNG-Logo_qcjuqf.png" alt="Client 1" />
          </Box>
          <Box className="clientLogo">
            <img src="https://res.cloudinary.com/dt98ksead/image/upload/v1731573716/Logo_ohwxwg.png" alt="Client 2" />
          </Box>
          <Box className="clientLogo">
            <img src="https://res.cloudinary.com/dt98ksead/image/upload/v1731573864/4-1_bdvtqo.png" alt="Client 3" />
          </Box>
          <Box className="clientLogo">
            <img src="https://res.cloudinary.com/dt98ksead/image/upload/b_rgb:000000/v1731573803/earthwise-logo-white-2-300x70_dbbgtj.png" alt="Client 1" />
          </Box>
          <Box className="clientLogo">
            <img src="https://res.cloudinary.com/dt98ksead/image/upload/v1731573645/tape_logo_1_mmeb3a.jpg" alt="Client 5" />
          </Box>
          <Box className="clientLogo">
            <img src="https://res.cloudinary.com/dt98ksead/image/upload/v1731573645/opto_jpzzhp.jpg" alt="Client 6" />
          </Box>
        </Slider>
      </Box>
    </Box>
  );
}

export default Home;
