import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home'; 
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import CareersPage from './pages/CareersPage'; 
import ServicePageTemplate from './pages/ServicePageTemplate';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path='/careers' element={<CareersPage/>}/>
        <Route path='/contact' element={<ContactUs/>}/>

        {/* Web Development */}
        <Route
          path="/customwebsite"
          element={
            <ServicePageTemplate
              title="Custom Websites"
              description="Create a stunning and responsive website tailored to your business needs."
              imageUrl="https://images.pexels.com/photos/109371/pexels-photo-109371.jpeg?auto=compress&cs=tinysrgb&w=600"
              benefits={[
                { title: "Responsive Design", description: "Your website will look great on any device no matter the screensize.", imageUrl: "https://images.pexels.com/photos/4158/apple-iphone-smartphone-desk.jpg?auto=compress&cs=tinysrgb&w=600" },
                { title: "SEO Optimization", description: "Get found on search engines with our built-in SEO strategies.", imageUrl: "https://images.pexels.com/photos/270637/pexels-photo-270637.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "Custom Solutions", description: "We tailor the features to match your unique business needs.", imageUrl: "https://images.pexels.com/photos/590041/pexels-photo-590041.jpeg?auto=compress&cs=tinysrgb&w=600" },
              ]}
            />
          }
        />
        <Route
          path="/ecommerce"
          element={
            <ServicePageTemplate
              title="E-Commerce Development"
              description="Launch your online store with a robust, user-friendly, and secure e-commerce platform."
              imageUrl="https://images.pexels.com/photos/230544/pexels-photo-230544.jpeg?auto=compress&cs=tinysrgb&w=600"
              benefits={[
                { title: "Secure Payments", description: "Integrated payment gateways ensure secure and seamless transactions.", imageUrl: "https://images.pexels.com/photos/29502367/pexels-photo-29502367/free-photo-of-mobile-payment-with-paypal-on-laptop-screen.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "Product Management", description: "Easily manage your products, inventory, and orders.", imageUrl: "https://images.pexels.com/photos/4348404/pexels-photo-4348404.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "Customizable Storefronts", description: "Design a storefront that reflects your brand and engages customers.", imageUrl: "https://images.pexels.com/photos/7857496/pexels-photo-7857496.jpeg?auto=compress&cs=tinysrgb&w=600" },
              ]}
            />
          }
        />
        <Route
          path="/maintenance"
          element={
            <ServicePageTemplate
              title="Website Maintenance"
              description="Ensure your website runs smoothly with regular updates and monitoring."
              imageUrl="https://images.pexels.com/photos/39284/macbook-apple-imac-computer-39284.jpeg?auto=compress&cs=tinysrgb&w=600"
              benefits={[
                { title: "Security Updates", description: "Keep your website secure with the latest patches and updates.", imageUrl: "https://images.pexels.com/photos/60504/security-protection-anti-virus-software-60504.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "Performance Optimization", description: "Ensure fast load times and high performance.", imageUrl: "https://images.pexels.com/photos/210012/pexels-photo-210012.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "Content Updates", description: "Update your website's content regularly to stay relevant.", imageUrl: "https://images.pexels.com/photos/209151/pexels-photo-209151.jpeg?auto=compress&cs=tinysrgb&w=600" },
              ]}
            />
          }
        />
        <Route
          path="/websiteupdates"
          element={
            <ServicePageTemplate
              title="Website Updates"
              description="Keep your website fresh and engaging with timely updates and enhancements."
              imageUrl="https://images.pexels.com/photos/1181263/pexels-photo-1181263.jpeg?auto=compress&cs=tinysrgb&w=600"
              benefits={[
                { title: "Design Enhancements", description: "Refresh your website design to match current trends.", imageUrl: "https://images.pexels.com/photos/3184298/pexels-photo-3184298.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "Feature Additions", description: "Add new features to keep your website competitive.", imageUrl: "https://images.pexels.com/photos/3184296/pexels-photo-3184296.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "Bug Fixes", description: "Resolve any issues quickly to maintain functionality.", imageUrl: "https://images.pexels.com/photos/11035537/pexels-photo-11035537.jpeg?auto=compress&cs=tinysrgb&w=600" },
              ]}
            />
          }
        />

        {/* App Development */}
        <Route
          path="/iosapps"
          element={
            <ServicePageTemplate
              title="iOS App Development"
              description="Build elegant and feature-rich iOS apps to captivate your users."
              imageUrl="https://images.pexels.com/photos/5082580/pexels-photo-5082580.jpeg?auto=compress&cs=tinysrgb&w=600"
              benefits={[
                { title: "App Store Deployment", description: "Seamlessly launch your app on the App Store.", imageUrl: "https://images.pexels.com/photos/1294886/pexels-photo-1294886.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "User-Centric Design", description: "Intuitive and user-friendly interfaces.", imageUrl: "https://images.pexels.com/photos/3861967/pexels-photo-3861967.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "Custom Functionality", description: "Features tailored to your business needs.", imageUrl: "https://images.pexels.com/photos/590020/pexels-photo-590020.jpeg?auto=compress&cs=tinysrgb&w=600" },
              ]}
            />
          }
        />
        <Route
          path="/androidapps"
          element={
            <ServicePageTemplate
              title="Android App Development"
              description="Create robust and scalable Android apps designed to elevate your business."
              imageUrl="https://images.pexels.com/photos/4716356/pexels-photo-4716356.jpeg?auto=compress&cs=tinysrgb&w=600"
              benefits={[
                { title: "Play Store Deployment", description: "Launch your app successfully on the Google Play Store.", imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpau4h1tEZPE9hnkfnYanSN8Xafe6w6Oqctw&s" },
                { title: "Custom Integrations", description: "Integrate third-party services seamlessly.", imageUrl: "https://images.pexels.com/photos/3184639/pexels-photo-3184639.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "High Performance", description: "Apps optimized for speed and usability.", imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgjQTL6f05leo9eRcaBQrb85FYrlhUIEcmRQ&s" },
              ]}
            />
          }
        />
        <Route
          path="/crossplatform"
          element={
            <ServicePageTemplate
              title="Cross-Platform App Development"
              description="Deliver apps that work seamlessly across Android, iOS, and other platforms."
              imageUrl="https://images.pexels.com/photos/16840499/pexels-photo-16840499/free-photo-of-couple-using-instagram-in-car.jpeg?auto=compress&cs=tinysrgb&w=600"
              benefits={[
                { title: "Cost Efficiency", description: "Develop once, deploy everywhere to save time and cost.", imageUrl: "https://images.pexels.com/photos/3184300/pexels-photo-3184300.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "Unified Experience", description: "Ensure consistent UI/UX across devices.", imageUrl: "https://images.pexels.com/photos/4158/apple-iphone-smartphone-desk.jpg?auto=compress&cs=tinysrgb&w=600" },
                { title: "Quick Deployment", description: "Launch faster with efficient development cycles.", imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNqsFCYb7T5C3bWPxYgdsxGboENUMwIshczw&s" },
              ]}
            />
          }
        />
        <Route
          path="/desktopapps"
          element={
            <ServicePageTemplate
              title="Desktop App Development"
              description="Build powerful desktop applications tailored to your business operations."
              imageUrl="https://images.pexels.com/photos/6889196/pexels-photo-6889196.jpeg?auto=compress&cs=tinysrgb&w=600"
              benefits={[
                { title: "Cross-Platform Compatibility", description: "Develop apps for Windows, macOS, and Linux.", imageUrl: "https://images.pexels.com/photos/356056/pexels-photo-356056.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "Custom Interfaces", description: "Create tailored designs to optimize workflow.", imageUrl: "https://images.pexels.com/photos/5082581/pexels-photo-5082581.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "Scalable Solutions", description: "Future-proof apps to grow with your business.", imageUrl: "https://images.pexels.com/photos/3184643/pexels-photo-3184643.jpeg?auto=compress&cs=tinysrgb&w=600" },
              ]}
            />
          }
        />

        <Route
          path="/uiux"
          element={
            <ServicePageTemplate
              title="UI/UX Design"
              description="Design intuitive and visually stunning interfaces for optimal user experiences."
              imageUrl="https://images.pexels.com/photos/196644/pexels-photo-196644.jpeg?auto=compress&cs=tinysrgb&w=600"
              benefits={[
                { title: "User Research", description: "Understand your audience for data-driven designs.", imageUrl: "https://images.pexels.com/photos/3184306/pexels-photo-3184306.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "Prototyping", description: "Visualize designs with clickable prototypes.", imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRg_pLBsu6pBFWRRmKFg26gyMC7QSKoFTP1ZA&s" },
                { title: "Modern Aesthetics", description: "Leverage cutting-edge design trends.", imageUrl: "https://images.pexels.com/photos/374870/pexels-photo-374870.jpeg?auto=compress&cs=tinysrgb&w=600" },
              ]}
            />
          }
        />
        <Route
          path="/branding"
          element={
            <ServicePageTemplate
              title="Branding"
              description="Develop a compelling brand identity that resonates with your audience."
              imageUrl="https://res.cloudinary.com/dt98ksead/image/upload/v1731688764/pexels-eva-bronzini-7661590_s11ltk.jpg"
              benefits={[
                { title: "Logo Design", description: "Create memorable logos that define your brand.", imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSX-H28dJIOW4NV0rqMDBrHJuAa3NMBzULDUQ&s" },
                { title: "Brand Strategy", description: "Develop a strategic plan to communicate your values.", imageUrl: "https://images.pexels.com/photos/3184638/pexels-photo-3184638.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "Marketing Collateral", description: "Consistent designs for business cards, brochures, and more.", imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTPCYoMS4p47AXU_MCoKPuYy7NCts3oRuosg&s" },
              ]}
            />
          }
        />
        <Route
          path="/graphicdesign"
          element={
            <ServicePageTemplate
              title="Graphic Design"
              description="Bring your ideas to life with stunning visuals and engaging designs."
              imageUrl="https://images.pexels.com/photos/3727456/pexels-photo-3727456.jpeg?auto=compress&cs=tinysrgb&w=600"
              benefits={[
                { title: "Infographics", description: "Simplify complex data with visually appealing graphics.", imageUrl: "https://images.pexels.com/photos/7948060/pexels-photo-7948060.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "Custom Illustrations", description: "Stand out with unique and creative visuals.", imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXUsIXQ8y9xu9r_I1DCy4C9-Bf-oo7IVWUMg&s" },
                { title: "Marketing Materials", description: "Design posters, flyers, and banners to promote your brand.", imageUrl: "https://images.pexels.com/photos/3184299/pexels-photo-3184299.jpeg?auto=compress&cs=tinysrgb&w=600" },
              ]}
            />
          }
        />
        <Route
          path="/elearnings"
          element={
            <ServicePageTemplate
              title="E-Learning Design"
              description="Develop engaging and effective e-learning solutions tailored to your needs."
              imageUrl="https://images.pexels.com/photos/577210/pexels-photo-577210.jpeg?auto=compress&cs=tinysrgb&w=600"
              benefits={[
                { title: "Interactive Modules", description: "Engage learners with interactive course content.", imageUrl: "https://images.pexels.com/photos/399129/pexels-photo-399129.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "SCORM Compatibility", description: "Integrate courses with Learning Management Systems.", imageUrl: "https://images.pexels.com/photos/3183197/pexels-photo-3183197.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "Custom Animations", description: "Bring concepts to life with tailored animations.", imageUrl: "https://images.pexels.com/photos/3184287/pexels-photo-3184287.jpeg?auto=compress&cs=tinysrgb&w=600" },
              ]}
            />
          }
        />

        <Route
          path="/dataanalysis"
          element={
            <ServicePageTemplate
              title="Data Analysis"
              description="Unlock actionable insights with advanced data analysis techniques."
              imageUrl="https://images.pexels.com/photos/577585/pexels-photo-577585.jpeg?auto=compress&cs=tinysrgb&w=600"
              benefits={[
                { title: "Data Cleaning", description: "Ensure your data is accurate and ready for analysis.", imageUrl: "https://images.pexels.com/photos/3184303/pexels-photo-3184303.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "Predictive Modeling", description: "Forecast trends and outcomes with confidence.", imageUrl: "https://images.pexels.com/photos/3184294/pexels-photo-3184294.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "Custom Dashboards", description: "Visualize key metrics to drive decisions.", imageUrl: "https://images.pexels.com/photos/577210/pexels-photo-577210.jpeg?auto=compress&cs=tinysrgb&w=600" },
              ]}
            />
          }
        />
        <Route
          path="/visualization"
          element={
            <ServicePageTemplate
              title="Data Visualization"
              description="Transform data into clear and compelling visuals for decision-making."
              imageUrl="https://images.pexels.com/photos/577210/pexels-photo-577210.jpeg?auto=compress&cs=tinysrgb&w=600"
              benefits={[
                { title: "Interactive Charts", description: "Drill down into data with dynamic visualizations.", imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWu3RpJtE8TbXYWxE9zc4mBKhmD1EaUQn-0ZmWeCkxBBd75J804EWlbl1pvathq_sAE0M&usqp=CAU" },
                { title: "Custom Reports", description: "Deliver insights with visually striking reports.", imageUrl: "https://images.pexels.com/photos/3184645/pexels-photo-3184645.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "Geospatial Mapping", description: "Map your data for location-based insights.", imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdfpvkGvfzWyPj0t31jWiK8C5SsnxrbKsYZCl2lc1pfCZ4TC6JOTYWynoN74DROR4CJ9w&usqp=CAU" },
              ]}
            />
          }
        />
        <Route
          path="/machinelearning"
          element={
            <ServicePageTemplate
              title="Machine Learning"
              description="Leverage cutting-edge machine learning techniques to power innovation."
              imageUrl="https://images.pexels.com/photos/8386440/pexels-photo-8386440.jpeg?auto=compress&cs=tinysrgb&w=600"
              benefits={[
                { title: "Custom Algorithms", description: "Solve complex challenges with tailored models.", imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9DpagL62AiVozsNM_SEvFtfKN5evyW8zwZF6kLXVg3JWh3ICYP0ATqRVmUMbJXuBPEuA&usqp=CAU" },
                { title: "Natural Language Processing", description: "Unlock insights from unstructured text data.", imageUrl: "https://images.pexels.com/photos/3184634/pexels-photo-3184634.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "Image Recognition", description: "Automate tasks with advanced image analysis.", imageUrl: "https://images.pexels.com/photos/3183174/pexels-photo-3183174.jpeg?auto=compress&cs=tinysrgb&w=600" },
              ]}
            />
          }
        />
        <Route
          path="/businessintelligence"
          element={
            <ServicePageTemplate
              title="Business Intelligence"
              description="Make data-driven decisions with customized business intelligence solutions."
              imageUrl="https://images.pexels.com/photos/7693733/pexels-photo-7693733.jpeg?auto=compress&cs=tinysrgb&w=600"
              benefits={[
                { title: "KPI Tracking", description: "Monitor performance with real-time dashboards.", imageUrl: "https://images.pexels.com/photos/3184647/pexels-photo-3184647.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "Trend Analysis", description: "Identify market trends to stay ahead.", imageUrl: "https://images.pexels.com/photos/3184648/pexels-photo-3184648.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "Scalable Solutions", description: "Adapt your BI tools as your business grows.", imageUrl: "https://images.pexels.com/photos/3184310/pexels-photo-3184310.jpeg?auto=compress&cs=tinysrgb&w=600" },
              ]}
            />
          }
        />

        <Route
          path="/socialmedia"
          element={
            <ServicePageTemplate
              title="Digital Marketing"
              description="Boost your brand presence and connect with your audience using cutting-edge digital marketing strategies."
              imageUrl="https://images.pexels.com/photos/607812/pexels-photo-607812.jpeg?auto=compress&cs=tinysrgb&w=600"
              benefits={[
                { title: "Social Media Strategy", description: "Engage and grow your audience on platforms like Facebook, Instagram, and LinkedIn.", imageUrl: "https://images.pexels.com/photos/1181354/pexels-photo-1181354.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "Ad Campaigns", description: "Create targeted ad campaigns to maximize your reach and ROI.", imageUrl: "https://images.pexels.com/photos/3184465/pexels-photo-3184465.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "Content Creation", description: "Craft compelling content that resonates with your audience.", imageUrl: "https://images.pexels.com/photos/4502704/pexels-photo-4502704.jpeg?auto=compress&cs=tinysrgb&w=600" },
              ]}
            />
          }
        />

        <Route
          path="/seo"
          element={
            <ServicePageTemplate
              title="SEO Services"
              description="Improve your search engine rankings and drive more organic traffic to your website."
              imageUrl="https://images.pexels.com/photos/270637/pexels-photo-270637.jpeg?auto=compress&cs=tinysrgb&w=600"
              benefits={[
                { title: "Keyword Optimization", description: "Identify and target the right keywords to boost your visibility.", imageUrl: "https://images.pexels.com/photos/3184292/pexels-photo-3184292.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "On-Page SEO", description: "Optimize your website's content and structure for search engines.", imageUrl: "https://images.pexels.com/photos/3184642/pexels-photo-3184642.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "Backlink Building", description: "Increase your site's authority with quality backlinks.", imageUrl: "https://images.pexels.com/photos/768125/pexels-photo-768125.jpeg?auto=compress&cs=tinysrgb&w=600" },
              ]}
            />
          }
        />

        <Route
          path="/uiux"
          element={
            <ServicePageTemplate
              title="UI/UX Design"
              description="Create intuitive, engaging, and user-friendly interfaces that captivate and retain your users."
              imageUrl="https://images.pexels.com/photos/326514/pexels-photo-326514.jpeg?auto=compress&cs=tinysrgb&w=600"
              benefits={[
                { title: "User Research", description: "Understand your audience's needs to craft targeted experiences.", imageUrl: "https://images.pexels.com/photos/3184306/pexels-photo-3184306.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "Wireframing & Prototyping", description: "Visualize and test designs before implementation.", imageUrl: "https://images.pexels.com/photos/3184464/pexels-photo-3184464.jpeg?auto=compress&cs=tinysrgb&w=600" },
                { title: "Interaction Design", description: "Enhance user satisfaction with seamless and engaging interfaces.", imageUrl: "https://images.pexels.com/photos/326503/pexels-photo-326503.jpeg?auto=compress&cs=tinysrgb&w=600" },
              ]}
            />
          }
        />
      
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
